import React from 'react';
import { useDispatch } from 'react-redux';
import { AddProduct } from '../../store/Slices/gifts/basketSlice';
import PropTypes from 'prop-types';
import Button from '../Button';
import PlusIcon from '../Icons/Plus';
import { isSantaLetterProduct } from '../../utils/Gifts/products';

const AddToBasketButtonForTeasers = ({ product }) => {
  const dispatch = useDispatch();

  const addToBasketClicked = () => {
    dispatch(AddProduct(product));
    //
    // if (location.pathname.match(/^\/basket|\/cards|\/checkout$/)) {
    //   window.scrollTo(0, 0);
    //   dispatch(basketBannerActions.hide());
    //   dispatch(
    //     messageActions.show(`${product.title} added, thanks for showing your support.`, {
    //       className: 'success-green',
    //       timeout: 4000,
    //     })
    //   );
    // }
  };

  if (isSantaLetterProduct(product)) {
    return null;
  }

  return (
    <Button
      className="add-to-basket-for-teasers"
      onClick={() => addToBasketClicked()}
      data-test="add-to-basket-for-teasers"
    >
      <em className="plus-icon">
        <PlusIcon />
        Add to basket
      </em>
    </Button>
  );
};

AddToBasketButtonForTeasers.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    categoryIds: PropTypes.arrayOf(PropTypes.number),
    image: PropTypes.object,
    url: PropTypes.object,
    shortDescription: PropTypes.string,
    relatedGiftsFilled: PropTypes.arrayOf(PropTypes.number),
    actionImage: PropTypes.object,
    actionDescription: PropTypes.string,
    annotation: PropTypes.string,
    giftsInBundle: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default AddToBasketButtonForTeasers;
